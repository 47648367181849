// noinspection SpellCheckingInspection

import React from 'react';
import styles from './Instructions.module.css';
import newReservationScreenshot from '../../assets/images/new_reservation.png'
import addedScreenshot from '../../assets/images/added.png'
import viewReservationsScreenshot from '../../assets/images/status.png'
import paymentScreenshot from '../../assets/images/payment.png'
import periodicPay1Screenshot from '../../assets/images/periodicPay1.png'
import periodicPay2Screensho from '../../assets/images/periodicPay2.png'
import redirectToMemberFeeButtonScreenshot from '../../assets/images/redirectToMemberFeeButton.png'
import memberFeeFormScreenshot from '../../assets/images/memberFeeForm.png'
import memberFeePayedScreenshot from '../../assets/images/memberFeePayd.png'
import feeForNonMemberScreenshot from '../../assets/images/nmenber.png'

const Instructions = () => {
    // noinspection JSValidateTypes
    return (
        <div className={styles.instructionsContainer}>
            <div className={styles.instructionText}>
                <h5>1. Rejestracja</h5>
                <p>Korzystanie z systemu rezerwacji wymaga założenia konta.
                    Z formularza do logowania nalezy wybrać "Zarejestruj się", wypełnij wymagane dane i postępuj zgodnie z instrukcjami.
                    Jeżeli w procesie rejestracji został podany poprawny adres e-mail, w ciągu kilku sekund na Twojej
                    skrzynce mailowej
                    pojawi się wiadomość z linkiem aktywacyjnym.
                    Należy w niego kliknąć aby dokończyć proces rejestracji. Link jest ważny 20
                    minut. Adres <b>Email</b> jest kluczowym elementem systemu, który wykorzystywany jest później do różnych celów,
                    w tym np. do zmiany zapomnianego hasła, dlatego stanowczo odradzamy zakładanie konta na "jednorazowy" adres email. Ponadto
                podawanie fikcyjnych danych podczas rejestracji może prowadzić do zawieszenia konta.</p>

            </div>
            <div className={styles.instructionText}>
                <h5>2. Dodanie nowej rezerwacji</h5>
                <div className={styles.imageContainer}>
                    <img src={newReservationScreenshot} alt="Rezerwacja" className={styles.floatingImageLeft}/>
                    <p>Obce rezerwacje są oznaczone kolorem czerwonym. Jasne komórki to wolne terminy.
                        Należy klikać
                        w wybrane komórki a na ekranie pojawi się projekcja planowanej rezerwacji (kolor niebieski).
                        Dla osób nie posiadających członkowstwa BTT dodatkowo będzie prezentowany koszt
                        rezerwacji. Do momentu kliknięcia w przycisk 'Rezerwuj' można dowolnie modyfikować
                        rezerwacje.</p>
                </div>
                <div className={styles.imageContainer}>
                    <img src={addedScreenshot} alt="rezerwacja dodana" className={styles.floatingImageRight}/>
                    <p>Po kliknięciu w przycisk 'Rezerwuj' oznaczone na niebiesko komórki zmienią kolor na zielony
                        (tym kolorem są zaznaczone własne rezerwacje). Dodanie nowej rezerwacji jest możliwe, jeżeli użytkownik
                    nie posiada zaległych płatności.</p>
                </div>
            </div>
            <div className={styles.instructionText}>
                <h5>3. Anulowanie rezerwacji </h5>
                <div className={styles.imageContainer}>
                    <img src={viewReservationsScreenshot} alt="przegląd rezerwacji"
                         className={styles.floatingImageLeft}/>
                    <p>Własne rezerwacje można przeglądać bezpośrednio na grafiku (oznaczone są one kolorem zielonym)
                        lub w zakładce 'Rezerwacje', gdzie prezentowane są wszystkie zaplanowane oraz archiwalne dane, dotyczące rezerwacji.
                        Do momentu rozpoczęcia zaplanowanej rezerwacji można ją anulowąć klikacjąc w znak 'X'.
                        Rezerwacje można też anulować bezpośrednio na grafiku, klikając w zielone komórki.<br/><b>Uwaga!</b> Każda rezerwacja może być anulowana przez Administratora,
                        dlatego przed przyjściem na korty, należy sprawdzić czy rezerwacja jest nadal aktualna. Informacje o anulowaniu rezerwacji przez administratora, wysyłane
                    są na adres mailowy rezerwującego.</p>
                </div>
            </div>
            <div className={styles.instructionText}>
                <h5>4. Płatności </h5>
                <div className={styles.imageContainer}>
                    <img src={paymentScreenshot} alt="płatności" className={styles.floatingImageRight}/>
                    <p> Ponieważ warunki pogodowe mogą uniemożliwić korzystanie z kortów, przyjęliśmy zasadę, że płatności wykonuje się po czasie rozpoczęcia rezerwacji.
                        Zobowiązania pojawią się w zakładce 'Płatności', do których użytkownik sam zostanie przeniesiony podczas logowania.
                        Jeżeli rezerwacja nie została anulowana, powstaje obowiązek zapłaty za nią bez względu na to czy rezerwacja została wykorzystana czy też nie. W przypadku gdy
                        korty nie nadają się do gry rezerwację może anulować administrator systemu.
                        Przycisk 'Płacę' przenosi do bramki płatności, gdzie za pomocą jednej z wielu metod (np. BLIK) można
                        dokonać płatności. Jeżeli proces płatności przebiegnie prawidłowo, opłacona rezerwacja znika z listy rezerwacji do opłacenia
                        .</p>
                </div>
                <div className={styles.imageContainer}>
                    <img src={periodicPay1Screenshot} alt="płatność okresowa 1" className={styles.floatingImageLeft}/>
                    <p>W sytuacji gdy właściciel konta nie posiada żadnych zaległych płatności pojawia się możliwość
                        wniesienia opłaty okresowej. Działa ona od razu (od momentu wpłaty) poprzez wybraną liczbę dni.
                        Do wyboru dostępny jest okres 7, 14 lub 30 dni wynajmu kortu (2 godziny dziennie).</p>
                </div>
                <div className={styles.imageContainer}>
                    <img src={periodicPay2Screensho} alt="Platnosc okresowa 2" className={styles.floatingImageRight}/>
                    <p>Po zakończeniu procesu płatności, w zakładce 'Płatności'
                        widoczna jest data, do której można rezerwować korty w ramach opłaty okresowej. Data ważności opłaty okresowej jest automatycznie przedłużana o każdy dzień niedostępności
                        kortów. Aby maksymalnie skorzystać z opłaty okresowej,
                        wpłatę najlepiej wykonać bezpośrednio przed rezerwacją kortów w danym dniu. Np. dla płatności na 7 dni, jeżeli wpłatę wykona się w sobotę rano, to począwszy od soboty do następnego piątku
                        można rezerwować korty 2 godziny dziennie.  W następną sobotę wypada już 8 dzień od momentu wpłaty, więc rezerwacja
                        w zakresie opłaty okresowej przestaje obowiązywać.
                        </p>
                </div>
            </div>
            <div className={styles.instructionText}>
                <h5>5. Składki członkowskie </h5>

                <div className={styles.imageContainer}>
                    <img src={redirectToMemberFeeButtonScreenshot} alt="button" className={styles.floatingImageLeft}/>
                    <p> W zakładce 'Płatności' w dolnej części ekranu znajduje się przycisk 'Chcę opłacić składkę BTT'.
                        Kliknięcie w niego przenosi do formularza opłacenia składki członkowskiej.
                    </p>
                </div>
                <div className={styles.imageContainer}>
                    <img src={memberFeeFormScreenshot} alt="formularz składki BTT"
                         className={styles.floatingImageLeft}/>
                    <p>Należy wybrać jedną z dostępnych opcji. Opłacenie składki członkowskiej wiąże się z
                        przynależnością
                        do Bocheńskiego Towarzystwa Tenisowego, którego podstawią działania jest Statut BTT. Po
                        akceptacji Statutu można przystąpić do zainicjowania płatności poprzez kliknięcie w przycisk
                        'Płacę'.</p>
                </div>
                <div className={styles.imageContainer}>
                    <img src={memberFeePayedScreenshot} alt="opłacona składka btt"
                         className={styles.floatingImageRight}/>
                    <p>Pozytywnie przeprowadzony proces opłacenia składki BTT powinien skutkować pojawieniem się w
                        zakładce 'Płatności'
                        informacji 'Składka BTT opłacona'. Odtąd wykonane rezerwacje nie będą obciążane kosztami wynajmu
                        kortu.</p>
                </div>
                <div className={styles.imageContainer}>
                    <img src={feeForNonMemberScreenshot} alt="nmember"
                         className={styles.floatingImageLeft}/>
                    <p>Gdy w grze biorą udział osoby <b>nie będące</b> członkami BTT, wtedy taka
                        rezerwacja powinna być oznaczona checkBox-em
                        "Dopłata za gracza spoza BTT". W takim przypadku zostanie naliczona opłata w wysokości 40% obowiązującej stawki
                        godzinowej. </p>
                </div>
            </div>


            <div className={styles.instructionText}>
                <h5>6. Statusy rezerwacji </h5>

                <div className={styles.imageContainer}>
                    <span> <b>REG</b> - rezerwacja zarejestrowana (do zapłaty), <br/>
                        <b>PAY</b> - rezerwacja opłacona, <br/>
                        <b>PER</b> - rezerwacja w ramach opłaty okresowej, <br/>
                        <b>CAN</b> - rezerwacja anulowana (brak obowiązku zapłaty), <br/>
                        <b>MEM</b> - rezerwacja w ramach składki członkowskiej BTT, <br/>
                        <b>NME</b> - rezerwacja z dopłatą za gracza spoza BTT (do zapłaty).
                    </span>
                </div>
            </div>

        </div>


    );
};

export default Instructions;