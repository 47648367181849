import React, {useState, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {
    fetchReservationCount,
    cancelReservation as cancelReservationApi,
    loadReservations,
    redirectToLoginIfInvalidToken
} from "../../api/api";
import CustomModal from "../../UI/Modal/CustomModal";
import {getStatus, formatDate, formatTime, canCancelReservation} from "../../utils";
import styles from "./MyReservations.module.css";
import Spinner from "../Spinner/Spinner";
import ErrorMessage from "../ErrorMessage/ErrorMessage";

const MyReservations = () => {
    const [reservations, setReservations] = useState([]);
    const [reservationType, setReservationType] = useState("current");
    const [currentPage, setCurrentPage] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const [hasNextPage, setHasNextPage] = useState(true);
    const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
    const [cancelingReservationId, setCancelingReservationId] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [modalMessage, setModalMessage] = useState("");
    const [showConfirmButton, setShowConfirmButton] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        redirectToLoginIfInvalidToken(navigate);
    }, [navigate]);

    useEffect(() => {
        const checkToken = () => {
            redirectToLoginIfInvalidToken(navigate);
        };

        window.addEventListener("storage", checkToken);
        return () => window.removeEventListener("storage", checkToken);
    }, [navigate]);

    const fetchReservations = async () => {
        try {
            const fetchedReservations = await loadReservations(currentPage - 1, reservationType);

            const sortedReservations = fetchedReservations.sort((a, b) => {
                if (reservationType === "current") {
                    return new Date(a.startTime) - new Date(b.startTime);
                } else {
                    return new Date(b.startTime) - new Date(a.startTime);
                }
            });
            setReservations(sortedReservations);
            setIsLoading(false);
        } catch (error) {
            console.error("Error while fetching reservations:", error);
            console.log(error);
            setError("Błąd pobrania rezerwacji");
            setIsLoading(false);
        }
    };

    const fetchPageCount = async () => {
        try {
            const count = await fetchReservationCount(reservationType);
            const countAsNumber = Number(count);
            setPageCount(Math.ceil(countAsNumber / 10));
            setHasNextPage(countAsNumber > currentPage * 10);
        } catch (error) {
            if (error.message === "Invalid or missing token") {
                navigate("/login", {replace: true});
            } else {
                console.error("Error while fetching page count:", error);
            }
        }
    };

    useEffect(() => {
        fetchReservations();
    }, [reservationType, currentPage]);


    useEffect(() => {
        fetchPageCount();
    }, [reservationType, currentPage]);


    useEffect(() => {
        setCurrentPage(1);
        setPageCount(0);
    }, [reservationType]);

    const handleButtonCancelClick = (reservationId) => {
        setCancelingReservationId(reservationId);
        setModalMessage("Czy na pewno chcesz anulować rezerwację?");
        setShowConfirmButton(true);
        setIsCancelModalOpen(true);
    };

    const handleConfirmCancel = async () => {
        if (cancelingReservationId) {
            try {
                const response = await cancelReservationApi(cancelingReservationId);
                setModalMessage(response.data);
                setCancelingReservationId(null);
                setShowConfirmButton(false);
                setIsCancelModalOpen(true);
            } catch (error) {
                if (error.message === "Invalid or missing token") {
                    navigate("/login", {replace: true});
                }
                console.error("Error while canceling reservation:", error);
                setModalMessage(error.response.data);
                console.log(error.response.data);
                setShowConfirmButton(false);
                setIsCancelModalOpen(true);
            }
        }
    };

    const handleCloseModal = () => {
        setIsCancelModalOpen(false);
        fetchReservations();
    };

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    if (isLoading) return <Spinner size={"large"}/>;
    if (error) return <ErrorMessage error={error}/>;

    return (
        <div className={styles.MyReservations}>
            <h1>Moje rezerwacje</h1>
            <div className={styles.radioButtons}>
                <label>
                    <input
                        type="radio"
                        value="current"
                        checked={reservationType === "current"}
                        onChange={(e) => setReservationType(e.target.value)}
                    />
                    Aktualne
                </label>
                <label>
                    <input
                        type="radio"
                        value="past"
                        checked={reservationType === "past"}
                        onChange={(e) => setReservationType(e.target.value)}
                    />
                    Przeszłe
                </label>
            </div>

            <CustomModal
                isOpen={isCancelModalOpen}
                onRequestClose={handleCloseModal}
                message={modalMessage}
                isPositiveMessage={modalMessage.includes("dodana") || modalMessage.includes("anulowana")}
                confirmLabel="Anuluj rezerwację"
                onConfirm={showConfirmButton ? handleConfirmCancel : undefined}
                confirmButtonColor="red"
                cancelLabel="Zamknij"
                cancelButtonColor="green"
            />

            <div className={styles.tableContainer}>

                {error ? (
                    <div className={styles.error}>
                        Wystąpił błąd podczas ładowania rezerwacji. Spróbuj ponownie później.
                    </div>
                ) : reservations.length === 0 ? (
                    <div className={styles.noReservations}>
                        Brak rezerwacji dla wybranego okresu
                    </div>
                ) : (
                    <table className={styles.reservationsTable}>
                        <thead>
                        <tr>
                            <th>Data</th>
                            <th>Kort</th>
                            <th>Czas</th>
                            <th>Stat</th>
                            <th>X</th>
                            <th>Kwota</th>
                            <th>Id</th>
                        </tr>
                        </thead>
                        <tbody>
                        {reservations.map(({id, status, startTime, courtId, endTime, updatedAt, cost}) => (
                            <tr key={id}
                                className={
                                    status === "CANCELED"
                                        ? styles.cancelledReservation
                                        : status === "PAYED"
                                            ? styles.payedReservation
                                            : status === "REGISTERED" && new Date(startTime) < new Date()
                                                ? styles.expiredReservation
                                                : ""
                                }
                            >
                                <td>{formatDate(startTime)}</td>
                                <td>{courtId}</td>
                                <td>{formatTime(startTime)} - {formatTime(endTime)}</td>
                                <td>{getStatus(status)}</td>
                                <td>
                                    {canCancelReservation(startTime, status) && (
                                        <div className={styles.cancelButtonContainer}>
                                            <button className={styles.button}
                                                    onClick={() => handleButtonCancelClick(id)}>
                                                X
                                            </button>
                                        </div>
                                    )}
                                </td>
                                <td>
                                    {(status === "REGISTERED" || status === "PAYED" || status === "NMEMBER" || status === "LESSONS") ? cost.toFixed(2) : ""}
                                </td>
                                <td>{id}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>

                )}
            </div>
            <div className={styles.pagination}>
                {pageCount > 1 && (
                    <>
                        <button
                            className={styles.prevNextButton}
                            disabled={currentPage === 1}
                            onClick={() => handlePageChange(currentPage - 1)}
                        >
                            Poprzednia
                        </button>
                        <span className={styles.pageNumber}>{currentPage}</span>
                        <button
                            className={styles.prevNextButton}
                            disabled={!hasNextPage}
                            onClick={() => handlePageChange(currentPage + 1)}
                        >
                            Następna
                        </button>
                    </>
                )}
            </div>
        </div>
    );
};

export default MyReservations;
