import React, {useEffect, useState} from "react";
import {useNavigate, useLocation} from 'react-router-dom';
import {
    addNewOffer,
    deleteOffer,
    fetchOffers,
    getUserData,
    redirectToLoginIfInvalidToken,
    updateOffer
} from "../../api/api";
import styles from './LookingPartner.module.css'
import Spinner from "../Spinner/Spinner";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import {FaEnvelope, FaFacebookMessenger, FaPhone} from "react-icons/fa";
import CustomModal from "../../UI/Modal/CustomModal";
import style from "../TourRegForm/TourRegForm.module.css";

const LookingPartner = () => {
    const [offers, setOffers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [newOffer, setNewOffer] = useState({
        date: '',
        startTime: '',
        endTime: '',
        addInfo: '',
        level: ''
    });
    const [editOffer, setEditOffer] = useState(null);
    const [userData, setUserData] = useState({});
    const [showForm, setShowForm] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showConfirmButton, setShowConfirmButton] = useState(false);
    const [confirmLabel, setConfirmLabel] = useState("");
    const [modalMessage, setModalMessage] = useState("");
    const [offerToDelete, setOfferToDelete] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const data = await getUserData();
            setUserData(data);
        };
        fetchData();
    }, []);

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        redirectToLoginIfInvalidToken(navigate, location.pathname);
    }, [navigate, location.pathname]);

    const handleChange = (event) => {
        const {name, value} = event.target;
        setNewOffer(prev => ({...prev, [name]: value}));
    };

    const fetchAllOffers = async () => {
        setIsLoading(true);
        try {
            const result = await fetchOffers();
            if (result.success) {
                const convertedOffers = result.data.map(offer => ({
                    ...offer,
                    startTime: offer.startTime.substring(0, 5),
                    endTime: offer.endTime.substring(0, 5)
                }));
                convertedOffers.sort((a, b) => new Date(a.date) - new Date(b.date));
                setOffers(convertedOffers);
                setIsLoading(false);
            }
        } catch (error) {
            console.error("Failed to fetch offers", error);
            setError("Błąd pobrania danych");
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchAllOffers();
    }, []);

    const validateForm = () => {
        const errors = [];

        if (!userData.showEmail && !userData.showPhone && !userData.showMessenger) {
            errors.push("Ustaw na swoim profilu przynajmniej jeden preferowany kontakt");
        }

        const oneMonthAdvance = new Date();
        oneMonthAdvance.setMonth(oneMonthAdvance.getMonth() + 1);

        const today = new Date();
        today.setHours(0, 0, 0, 0);


        if (new Date(newOffer.date) > oneMonthAdvance) {
            errors.push("Maksymalna data to jeden miesiąc wprzód.");
        }
        if (new Date(newOffer.date) < today) {
            errors.push("Data nie może być starsza niż dzisiejsza");
        }
        if (newOffer.startTime >= newOffer.endTime) {
            errors.push("Czas rozpoczęcia musi być wcześniejszy niż czas zakończenia.");
        }
        if (newOffer.addInfo.length > 200) {
            errors.push("Dodatkowa informacja nie może być dłuższa niż 200 znaków.");
        }
        return errors;
    };


    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);

        const validationErrors = validateForm();

        if (validationErrors.length > 0) {
            setModalMessage(validationErrors.join(" "));
            setIsModalOpen(true);
            setIsLoading(false);
            return;
        }

        try {
            const newOfferWithUser = {...newOffer, userId: userData.id};
            const result = editOffer
                ? await updateOffer(editOffer.id, newOfferWithUser)
                : await addNewOffer(newOfferWithUser);
            if (result.success) {
                setShowForm(false);
                setNewOffer({
                    date: '',
                    startTime: '',
                    endTime: '',
                    addInfo: '',
                    level: ''
                });
                setEditOffer(null);
                fetchAllOffers();
                setModalMessage(result.data);
                setIsModalOpen(true);
            } else {
                setModalMessage(result.data);
                setIsModalOpen(true);
            }
        } catch (error) {
            console.error("Failed to submit new offer", error);
            setError("Error submitting new offer");


        }
        setIsLoading(false);
    };

    const handleContactClick = (contact, type) => {
        if (type === 'email') {
            window.location.href = `mailto:${contact}`;
        } else if (type === 'phone') {
            window.location.href = `tel:${contact}`;
        } else if (type === 'messenger') {
            window.location.href = `https://m.me/${contact}`;
        }
    };


    const generateTimeOptions = () => {
        const times = [];
        for (let hour = 7; hour < 22; hour++) {
            for (let minute = 0; minute < 60; minute += 30) {
                const time = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
                times.push(time);
            }
        }
        return times;
    };

    const timeOptions = generateTimeOptions();


    const handleEdit = (offerId) => {
        const offer = offers.find(offer => offer.id === offerId);
        setEditOffer(offer);
        setNewOffer({
            date: offer.date,
            startTime: offer.startTime,
            endTime: offer.endTime,
            addInfo: offer.addInfo,
            level: offer.level
        });
        setShowForm(true);
    };

    const handleDelete = async () => {
        if (offerToDelete) {
            setIsLoading(true);
            try {
                const result = await deleteOffer(offerToDelete);
                if (result.success) {
                    setModalMessage(result.data);
                } else {
                    setModalMessage("Usunięcie nieudane");
                }
            } catch (error) {
                console.error("Failed to delete offer", error);
                setModalMessage("Błąd podczas usuwania oferty.");
            }
            setIsLoading(false);
            setIsModalOpen(true);
            setShowConfirmButton(false);
            setOfferToDelete(null);
            fetchAllOffers();
        }
    };

    const handleDeleteConfirmation = (offerId) => {
        setOfferToDelete(offerId);
        setModalMessage("Czy na pewno chcesz usunąć kartę?");
        setConfirmLabel("Tak, usuwam");
        setShowConfirmButton(true);
        setIsModalOpen(true);
    };

    useEffect(() => {
        if (!showForm) {
            setNewOffer({
                date: '',
                startTime: '',
                endTime: '',
                addInfo: '',
                level: ''
            });
            setEditOffer(null);
        }
    }, [showForm]);

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const isOfferExpired = (date) => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        const offerDate = new Date(date);
        offerDate.setHours(0, 0, 0, 0);

        return offerDate < today;
    };

    const sortedOffers = offers.sort((a, b) => new Date(a.date) - new Date(b.date));
    const filteredOffers = sortedOffers.filter(offer => !isOfferExpired(offer.date) || userData.id === offer.userId);


    if (isLoading) return <Spinner size={"large"}/>;
    if (error) return <ErrorMessage error={error}/>;


    return (
        <>
            <CustomModal
                isOpen={isModalOpen}
                onRequestClose={handleCloseModal}
                message={modalMessage}
                confirmLabel={showConfirmButton ? confirmLabel : undefined}
                onConfirm={showConfirmButton ? handleDelete : undefined}
                isPositiveMessage={modalMessage.includes("Zapisano") || modalMessage.includes("Skasowane") || modalMessage.includes("Zaktualizowane")}
                cancelLabel="Zamknij"
                confirmButtonColor="red"
                cancelButtonColor="green"
            />
            <div className={styles.container}>
                <h5 className={styles.title}>Szukam partnera do gry</h5>
                <div className={styles.addOfferContainer}>
                    <div className={style.scoreRules}>
                        <a href="/ntrp-scale" target="_blank">Opis</a>
                    </div>
                    <button className={styles.addOfferButton} onClick={() => setShowForm(!showForm)}>
                        {showForm ? "Zamknij formularz" : "Dodaj nową kartę"}
                    </button>

                </div>
                {showForm && (
                    <div className={styles.formContainer}>
                        <form onSubmit={handleSubmit}>
                            <label htmlFor="level">NTRP</label>
                            <select
                                id="level"
                                name="level"
                                value={newOffer.level}
                                onChange={handleChange}
                                required
                            >
                                <option value="">Wybierz</option>
                                <option value="1.0">1.0</option>
                                <option value="1.5">1.5</option>
                                <option value="2.0">2.0</option>
                                <option value="2.5">2.5</option>
                                <option value="3.0">3.0</option>
                                <option value="3.5">3.5</option>
                                <option value="4.0">4.0</option>
                                <option value="4.5">4.5</option>
                                <option value="5.0">5.0</option>
                                <option value="5.5">5.5</option>

                            </select>
                            <label htmlFor="date">Data</label>
                            <input
                                type="date"
                                id="date"
                                name="date"
                                value={newOffer.date}
                                onChange={handleChange}
                                required
                            />
                            <label htmlFor="startTime">W godzinach od</label>
                            <select
                                id="startTime"
                                name="startTime"
                                value={newOffer.startTime}
                                onChange={handleChange}
                                required
                            >
                                <option value="">Wybierz</option>
                                {timeOptions.map((time) => (
                                    <option key={time} value={time}>{time}</option>
                                ))}
                            </select>
                            <label htmlFor="endTime">do</label>
                            <select
                                id="endTime"
                                name="endTime"
                                value={newOffer.endTime}
                                onChange={handleChange}
                                required
                            >
                                <option value="">Wybierz</option>
                                {timeOptions.map((time) => (
                                    <option key={time} value={time}>{time}</option>
                                ))}
                            </select>
                            <label htmlFor="addInfo">Dodatkowa informacja</label>
                            <textarea
                                id="addInfo"
                                name="addInfo"
                                value={newOffer.addInfo}
                                onChange={handleChange}
                                placeholder="np. często mogę grać w godzinach przed południem, etc ..."
                            />
                            <button type="submit" className={styles.submitButton}>
                                {editOffer ? "Edytuj" : "Zapisz"}
                            </button>
                        </form>
                    </div>
                )}

                {filteredOffers.length === 0 ? (
                    <p className={styles.addInfo}>Aktualnie brak ogłoszeń.</p>
                ) : (
                    <div className={styles.offerContainer}>
                        {sortedOffers.map((offer) => (
                            (userData.id === offer.userId || !isOfferExpired(offer.date)) && (
                                <div key={offer.id}
                                     className={`${styles.offerCard} ${isOfferExpired(offer.date) ? styles.pastOfferCard : ''}`}>
                                    <div className={styles.cardHeader}>
                                        <h5>{offer.firstName}</h5>
                                        <span className={styles.boldText}>NTRP {offer.level}</span>
                                    </div>
                                    <p className={styles.offerDate}>{`${new Date(offer.date).toLocaleDateString('pl-PL', {
                                        weekday: 'long',
                                        day: 'numeric',
                                        month: 'long'
                                    })}`}</p>
                                    <p className={styles.offerTime}>{`${offer.startTime} - ${offer.endTime}`}</p>
                                    <p className={styles.addInfo}>{offer.addInfo}</p>
                                    <div className={styles.contactIcons}>
                                        {offer.userShowEmail &&
                                            <FaEnvelope onClick={() => handleContactClick(offer.userEmail, 'email')}/>}
                                        {offer.userShowPhone &&
                                            <FaPhone onClick={() => handleContactClick(offer.userPhone, 'phone')}/>}
                                        {offer.userShowMessenger && <FaFacebookMessenger
                                            onClick={() => handleContactClick(offer.userMessenger, 'messenger')}/>}
                                    </div>
                                    {userData.id === offer.userId && (
                                        <div className={styles.ownerButtons}>
                                            <button onClick={() => handleEdit(offer.id)}>Edytuj</button>
                                            <button onClick={() => handleDeleteConfirmation(offer.id)}>Usuń</button>
                                        </div>
                                    )}
                                </div>
                            )
                        ))}
                    </div>
                )}
            </div>
        </>
    );
};

export default LookingPartner;

